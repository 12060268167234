@mixin lineClamp($lines) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$lines};
    overflow: hidden;
}

@mixin wh100 {
    width: 100%;
    height: 100%;
}

@mixin wh($size) {
    width: #{$size};
    height: #{$size};
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin useFont($font) {
    font-family: "#{$font}";
}

@mixin rtl() {
    [dir="rtl"] & {
        @content;
    }
}
