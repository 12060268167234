@use "sass:map";

$easings: (
    "elastic": cubic-bezier(.19,1,.22,1),
    "material": cubic-bezier(.4,0,.2,1),
);

$easing: map.get($easings, "elastic");

$green1: #7a9c74;
$green2: #9cc623;

$light1: #f9f7f3;

$primary: $green2;
$dark2: #252C30;

$font-main: "Barlow";
$font-secondary: "Barlow";

:root {
    --bs-body-font-family: "#{$font-main}", var(--bs-font-sans-serif) !important;

    --green1: #{$green1};
    --green2: #{$green2};

    --primary: #{$primary};
    --primary-light: #{lighten($primary, 10%)};
    --secondary: #6c757d;
    --dark: #222;
    --dark2: #252C30;
    --gradient: linear-gradient(to right bottom, var(--primary-light), var(--primary));

    --header-h: 80px;
}

$progress-height: 10px;

$breadcrumb-divider-color: $primary;
