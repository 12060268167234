@keyframes zoomIn {
    from {
        transform: scale3d(1, 1, 1);
    }

    to {
        transform: scale3d(1.3, 1.3, 1);
    }
}

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
