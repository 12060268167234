@import 'partials/mixins';

@import 'partials/variables';

@import "~bootstrap/scss/bootstrap";

@import 'partials/keyframes';

a {
    color: var(--dark);
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    transition: color 0.3s $easing;

    &:hover {
        color: var(--primary);
    }
}

picture {
    display: contents;
}

img {
    max-width: 100%;
}

body {
    letter-spacing: .25px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
    max-width: 100%;
    overflow-x: hidden;
}

p {
    line-height: 2;
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
}

header {
    &.sticky {
        .stickymenu {
            position: fixed;
            width: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            box-shadow: $box-shadow;
            animation: slideInDown .5s $easing forwards;
        }
    }
}

.topbar {
    background: #1c1c27;
    color: #a5a5a5;
    min-height: 42px;

    // > .container {
    //     padding-top: 15px;
    //     padding-bottom: 15px;
    // }

    .dropdown-toggle {
        color: currentColor;
        border: 0;
        gap: 8px;
    }

    .flag-icon {
        margin-inline-end: 5px;
    }

    .countdown {
        height: 100%;
        display: inline-flex;
        align-items: center;
        margin-inline-start: 10px;

        span {
            background: linear-gradient(to right, #ff7f01, #ff0501);
            font-weight: 700;
            color: #fff;
            font-size: 1.25rem;
            padding: 10px;
            margin-inline-end: 10px;
        }
    }
}

.header-offer {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        margin-bottom: 0;
        line-height: 1;
    }
}

.flag-icon {
    @include wh(20px);
}

.searchmenu {
    background: #20202d;
    padding: 10px 0;

    > .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }
    }

    .input-group {
        input {
            padding: 15px 30px;
            width: 350px;
            border-radius: 9999px 0 0 9999px;
            border: 0;

            &:focus {
                box-shadow: none;
                border: 0;
            }

            @include rtl {
                border-radius: 0 9999px 9999px 0 !important;
            }
        }

        .btn {
            border-radius: 0 9999px 9999px 0;
            font-size: 18px;
            padding: 0 25px;
            border: 0;

            @include rtl {
                border-radius: 9999px 0 0 9999px !important;
            }
        }
    }

    .searchbar {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.stickymenu {
    background: #fff;
    z-index: 3;
    position: relative;

    .navbar-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.catmenu {
    position: relative;

    &.active {
        .category-menu {
            max-height: 9999px;
            visibility: visible;
            opacity: 1;
            overflow: unset;
            padding: 15px 0;
            transform: scaleY(1);
        }
    }
}

.cat-toggle {
    height: var(--header-h);
    background: $primary;
    color: #fff;
    @include flexCenter;
    gap: 10px;
    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
        color: #fff;
    }
}

.category-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    width: 100%;
    position: absolute;
    z-index: 5;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: all .5s $easing;

    li {
        a {
            text-transform: uppercase;
            color: #282828;
            font-weight: 600;
            display: block;
            padding: 10px 25px;

            &:hover {
                color: $primary;
            }
        }
    }
}

.menu {
    flex: 1;
    min-width: 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-secondary {
        display: flex;
        gap: 30px;
        padding-top: 10px;
        justify-content: center;
        align-items: center;

        .item {
            cursor: pointer;
            color: var(--dark);
            font-size: 1.5rem;

            &:hover {
                color: var(--primary);
            }
        }

        .cart {
            position: relative;

            &::after {
                content: attr(data-items);
                position: absolute;
                top: -10px;
                right: -10px;
                font-weight: 500;
                font-size: .5em;
                background: var(--primary);
                padding: 2px 5px;
                border-radius: 9999px;
                color: #fff;
                min-width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                // transform: translate(0, -100%);
            }
        }
    }
}

.topmenu {
    display: none;
    justify-content: flex-end;
    font-weight: 600;
    height: var(--header-h);
    margin: 0;

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    .sub-menu {
        background: #fff;
        box-shadow: 0 5px 5px 0 rgba(#000, .15);
        // border-top: 1px solid #eee;
        width: 240px;
        color: #000;
        transform: translateY(-20px) scaleY(0);
        opacity: 0;
        transform-origin: top;

        > li {
            // padding: 15px;
            a {
                padding: 15px;
                width: 100%;
                line-height: 1.5;
                transition: color 0.3s $easing;
            }

            &:hover {
                background: transparent;
            }
        }
    }

    > li {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .sub-menu {
            opacity: 0;
            pointer-events: none;
            transition: .5s $easing;
            position: absolute;
            top: calc(var(--header-h));
            // left: 0;
            inset-inline-start: 0;
            // margin-top: 10px;
            z-index: 5;
            // border-bottom: 5px solid $primary;
            --dark: #282828;
            font-size: 0.95em;
            // border-radius: 5px;
            padding: 15px;
            padding-inline-end: 0;

            > li {
                position: relative;

                > .sub-menu {
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translateX(95%);
                    background: #fff;
                    opacity: 0;
                    pointer-events: none;
                    transition: .35s $easing;
                }
            }

            &:hover {
                > li:hover {
                    > .sub-menu {
                        opacity: 1;
                        pointer-events: all;
                        transform: translateX(100%);
                    }
                }
            }
        }

        &:hover {
            color: var(--primary);

            > .sub-menu {
                opacity: 1;
                pointer-events: all;
                margin-top: 0;
                transform: translateY(0) scaleY(1);
            }
        }

        // &:not(:last-child) {
        //     margin-right: 15px;
        // }

        > a {
            text-transform: uppercase;
            white-space: nowrap;
            height: 100%;
            display: inline-flex;
            align-items: center;
            transition: .25s $easing;
            padding: 0 20px;
            position: relative;

            &::before,
            &::after {
                content: '';
                opacity: 0;
                position: absolute;
                opacity: 0;
                z-index: -1;
                transition: all .3s $easing;
            }

            &::before {
                background: $primary;
                width: 100%;
                height: 50px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% + 3px)) scale(-1, .85);
                clip-path: url(#menuclip);

                @include rtl {
                    transform: translate(-50%, calc(-50% + 3px)) scale(1, 0.85);
                }
            }

            &::after {
                @include wh(20px);
                top: 7px;
                inset-inline-start: 5px;
                background: url(../images/icons/leaf.svg) no-repeat center / 100% 100%;
            }
        }

        &.active,
        &:hover {
            > a {
                color: #fff;

                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .menu-cta {
        &:hover {
            > a {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .sub-menu {
            // left: unset;
            inset-inline-start: unset;
            inset-inline-end: 0;
            padding: 25px;
            width: 350px;

            .form-control {
                padding: 10px 20px;

                &[name="phone"] {
                    padding-left: 50px;
                }
            }
        }
    }
}

.topmenu-m {
    --dark: #eee;

    > li {
        text-transform: uppercase;
        // padding: 5px 0;

        > a {
            color: var(--primary);
        }
    }

    .sub-menu {
        li {
            text-transform: none;
        }
    }

    a {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;

        & + .sub-menu {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            padding-left: 25px;
            transition: .3s $easing;
        }

        .btn-icon {
            transition: transform .3s $easing;
            color: $primary;
        }

        &.active {
            & + .sub-menu {
                max-height: 1000px;
                opacity: 1;
                transition: .3s $easing;
            }

            .btn-icon {
                transform: rotate(180deg);
            }
        }
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    height: 100%;
    background: #111;
    top: 0;
    right: 0;
    z-index: 120;
    width: 280px;
    padding: 25px;
    transform: translateX(100%);
    opacity: 0;
    transition: .8s $easing;
    transition-property: opacity, transform;
    overflow: auto;

    @include media-breakpoint-up(sm) {
        width: 350px;
    }

    @include media-breakpoint-up(md) {
        width: 400px;
    }

    .close {
        align-self: flex-end;
        // position: absolute;
        // top: 25px;
        // right: 25px;
        color: #fff;
        font-size: 24px;
        transition: color .3s $easing;

        &:hover {
            color: var(--primary);
        }
    }

    &-content {
        flex: 1;

        .title {
            font-weight: 700;
            font-size: 1.2rem;
            text-transform: uppercase;
            margin-bottom: 10px;
            color: $primary;
        }
    }

    ~ .sidebar-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(#888, .7);
        top: 0;
        left: 0;
        z-index: 110;
        opacity: 0;
        pointer-events: none;
        transition: opacity .5s $easing;
        cursor: pointer;
        backdrop-filter: blur(5px);
    }

    &.open {
        opacity: 1;
        transform: translateX(0);

        ~ .sidebar-overlay {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.mnav-toggle {
    height: var(--header-h);
    width: 50px;
    border-radius: 0;

    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

.section {
    --spadding: 50px;
    padding-top: var(--spadding);
    padding-bottom: var(--spadding);
    position: relative;

    &-bg-light {
        background: $light1;
    }
}

.section-hero {
    color: #fff;
    position: relative;

    .splide {
        &__slide {
            &.is-active.is-visible {
                .hero__img {
                    animation: zoomIn 15s linear;
                }
            }
        }

        &__pagination {
            bottom: 50%;
            left: unset;
            right: 0;
            flex-direction: column;
            padding: 0 2em;
            gap: 20px;
            transform: translateY(50%);

            &__page {
                background: #fff;
                @include wh(10px);
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include wh(24px);
                    border: 2px solid transparent;
                    border-radius: 50%;
                }

                &.is-active {
                    background: $primary;

                    &::after {
                        border-color: $primary;
                    }
                }
            }
        }
    }

    .follow {
        position: absolute;
        top: 50%;
        left: 2em;
        transform: translateY(-50%);
        color: $primary;
        z-index: 1;
        @include flexCenter;
        flex-direction: column-reverse;
        gap: 70px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        span {
            writing-mode: vertical-lr;
            transform: scale(-1);

            &::after {
                content: '';
                height: 50px;
                width: 1px;
                background: currentColor;
                position: absolute;
                bottom: -10px;
                right: 50%;
                transform: translate(-50%, 100%);
            }
        }

        &-social {
            --dark: #fff;
            @include flexCenter;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.hero {
    &__slide {
        height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 200px);
        position: relative;
    }

    &__img {
        position: relative;
        overflow: hidden;
        z-index: 1;
        height: 100%;

        &::after {
            content: '';
            background: rgba(#000, .5);
            position: absolute;
            top: 0;
            left: 0;
            @include wh100;
        }

        img {
            @include wh100;
            object-fit: cover;
        }
    }

    &__text {
        position: absolute;
        top: 0;
        left: 0;
        @include wh100;
        z-index: 2;
        text-align: center;
        color: #fff;
        @include flexCenter;
        flex-direction: column;

        .title,
        .content {
            width: 100%;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(md) {
                width: 60vw;
            }
        }

        .title {
            --fs: 2rem;
            font-size: var(--fs);
            line-height: 1.2;
            font-weight: 700;

            @include media-breakpoint-up(sm) {
                --fs: 3rem;
            }

            @include media-breakpoint-up(md) {
                --fs: 4rem;
            }

            @include media-breakpoint-up(xl) {
                --fs: 5rem;
            }

            div {
                color: $green2;
            }
        }

        .content {
            margin-top: 20px;
        }

        .lead {
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }

        .btn {
            border-radius: 9999px;
            padding: 10px 40px;
        }
    }
}

.btn {
    font-weight: 600;
    transition: .3s $easing;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all .3s $easing;

    &-primary {
        color: #fff;
    }

    &-icon {
        border: 0;
    }

    &-transparent {
        background: transparent;
    }

    &-comfortable {
        padding: 10px 40px;
    }

    &-dark {
        &:hover {
            background: $primary;
            border-color: $primary;
        }
    }

    &-gradient {
        background-image: linear-gradient(to right, #ff7f01 0%, #ff0501 50%, #ff7f01 100%);
        background-size: 200% auto;
        color: #fff;
        text-transform: uppercase;
        border: 0;
        transition: all .4s linear;

        &:hover {
            background-position: right center;
            color: #fff;
        }
    }
}

.prod {
    &__wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 40px 20px;

        @include media-breakpoint-up(sm) {
            display: grid;
            --cols: 2;
            grid-template-columns: repeat(var(--cols, 2), 1fr);
            justify-items: center;
        }

        @include media-breakpoint-up(md) {
            --cols: 3;
        }

        @include media-breakpoint-up(xl) {
            --cols: 6;
        }
    }

    &__item {
        text-align: center;
        height: 100%;
        position: relative;

        @include media-breakpoint-up(xl) {
            &:not(:last-child) {
                &::after {
                    content: '';
                    position: absolute;
                    top: 40px;
                    inset-inline-end: 0;
                    transform: translateX(50%);
                    width: 50px;
                    border-top: 1px dashed #252c30;

                    @include rtl {
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    &__icon {
        @include wh(80px);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: $primary;
        border-radius: 50%;
        color: #fff;

        svg,
        img {
            @include wh(50%);
        }
    }

    &__title {
        @include useFont($font-secondary);
        font-weight: 700;
        margin: 15px 0 5px;
        font-size: 1.35rem;
        text-transform: capitalize;
    }

    &__sub {
        color: #252C30;
        font-size: .875rem;
        visibility: collapse;
        max-height: 0;
    }
}

.section-title {
    position: relative;
    margin-bottom: 70px;

    &::after {
        content: '';
        width: 137px;
        height: 16px;
        bottom: -30px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        background: url(../images/leaf.webp) no-repeat center / 100% 100%;
        position: absolute;

        @include rtl {
            transform: translateX(50%);
        }
    }

    &:not(.text-center) {
        &::after {
            inset-inline-start: 0;
            transform: unset;
        }
    }

    .sub,
    .title {
        position: relative;
        z-index: 2;
    }

    .sub {
        font-weight: 600;
        color: $green1;
        font-size: .95rem;
        text-transform: uppercase;
    }

    .title {
        font-weight: 800;
        text-transform: uppercase;
    }

    .sub,
    .title,
    p {
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(md) {
            width: 60vw;
        }
    }

    p {
        --px: 5%;
        font-size: 1.25em;
        padding-left: var(--px);
        padding-right: var(--px);
        line-height: 1.5;

        @include media-breakpoint-up(md) {
            --px: 15%;
        }
    }
}

.section-about {
    .section-content {
        p {
            text-align: justify;
        }

        .btn {
            border-radius: 9999px;
            padding: 10px 40px;
        }
    }
}

.pcard {
    background: #fff;
    // box-shadow: $box-shadow-sm;
    padding: 25px;
    border-radius: 10px;
    text-align: center;
    margin-top: 150px;
    position: relative;
    height: calc(100% - 172px);

    &__wrap {
        padding: 20px;
        margin: 10px 0;
        height: 100%;
    }

    &__img {
        margin-top: -150px;
        height: 250px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        img {
            @include wh100;
            object-fit: cover;
        }
    }

    &__title {
        margin-top: 15px;
        text-transform: uppercase;
        font-size: 1.15rem;
    }

    &__sub {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 0.875rem;
    }

    &__btn {
        font-weight: 700;
        position: absolute;
        left: 50%;
        bottom: 0;
        padding: 10px 30px;
        border-radius: 9999px;
        transform: translate(-50%, 50%);
    }
}

.nav-tabs {
    border: 0;
    gap: 20px;
    justify-content: center;
    margin-bottom: 40px;

    .nav-link {
        border: 1px solid #e5e4e4;
        // text-transform: uppercase;
        font-weight: 600;
        color: #858484;
        // font-size: 1.15rem;
        transition: .3s $easing;
        position: relative;
        padding: 10px 20px;
        border-radius: 9999px;

        &.active,
        &:hover {
            background: $primary;
            border-color: $primary;
            color: #fff;
        }
    }
}

.pdcard {
    display: block;
    border: 1px solid #ecebea;
    background: #fff;

    &__img {
        width: 100%;
        height: 230px;

        img {
            @include wh100;
            object-fit: cover;
        }
    }

    &__header {
        padding: 25px;
        border-bottom: 1px solid #ededed;
    }

    &__name {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &__cat {
        font-size: .75rem;
        color: #7b7b7d;
        font-weight: 700;

        span {
            color: #eb0909;
        }
    }

    &__footer {
        @include flexCenter;
        padding: 15px 25px;
        font-weight: 600;
    }

    &__rating {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #e3b12e;
        font-size: 10px;
        margin-bottom: 10px;
    }
}

.section-stat {
    background: url(../images/bg/bg1.webp) no-repeat center / cover;
    --spadding: 120px;
}

.fcard {
    background: #424d3e;
    position: relative;
    border: 1px dotted #6c6b6b;
    padding: 40px;
    transition: .3s $easing;
    color: $primary;
    border-radius: $border-radius;
    transition: .3s $easing;
    height: 100%;

    &:hover {
        border-color: $primary;
        box-shadow: $box-shadow-lg;
    }

    &__icon {
        position: absolute;
        top: 20px;
        inset-inline-end: 20px;
        @include wh(50px);
        color: currentColor;
        opacity: .4;
    }

    &__title {
        color: #fff;
        font-size: 2.5rem;
        margin-bottom: 10px;
        line-height: 1;
        font-weight: 700;
    }

    &__sub {
        text-transform: uppercase;
        font-weight: 600;
    }
}

.info {
    &__item {
        display: flex;
        gap: 20px;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &:hover {
            .info {
                &__icon {
                    background: $primary;
                    color: #fff;
                }
            }
        }
    }

    &__icon {
        @include wh(100px);
        border-radius: 50%;
        border: 3px dotted #ebebeb;
        color: #9e9e9d;
        flex: 0 0 auto;
        @include flexCenter;
        transition: .3s $easing;

        svg {
            @include wh(50%);
        }
    }

    &__content {
        flex: 1;
    }

    &__title {
        font-weight: 600;
        font-size: 1.125rem;
        margin-bottom: 10px;
    }

    &__sub {
        font-size: 0.875rem;
    }
}

.footer {
    background: #f1f1f1;

    > .container {
        padding-bottom: 70px;
    }

    .col-title {
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            color: #7d7d7d;

            &:not(:last-child) {
                margin-bottom: 15px;
            }

            a {
                color: #7d7d7d;
                font-size: 0.875rem;
            }
        }
    }

    .footer-text {
        p {
            font-weight: 500;
        }
    }

    .footer-social {
        display: flex;
        gap: 30px;
    }

    .footer-contact {
        li {
            font-weight: 600;

            i,svg {
                margin-right: 10px;
            }
        }
    }

    .copyright {
        background: #dcdada;
        color: #7c7c7c;
        font-weight: 500;
        font-size: 0.875rem;

        .section {
            --spadding: 20px;
            text-align: center;
        }

        a {
            color: #404a3d;

            &:hover {
                color: darken(#404a3d, 5%);
            }
        }
    }
}

.newsletter {
    --spadding: 40px;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 70px;

    &-title {
        span {
            font-size: 0.875rem;
        }
    }

    input {
        border: 0;
        padding: 20px;

        &:focus {
            box-shadow: none;
        }
    }

    &-form {
        .input-group {
            .btn {
                @include rtl {
                    border-radius: $border-radius 0 0 $border-radius !important;
                }
            }
        }
    }
}

.tmcard {
    background: #fff;
    box-shadow: $box-shadow;
    @include wh100;
    padding: 10px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    &__inner {
        border: 1px dotted #cecece;
        padding: 25px;
    }

    &__wrap {
        @include wh100;
        padding: 20px;
        border-radius: 5px;
    }

    &__elem {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        color: $primary;
        z-index: 1;

        svg {
            min-height: 60px;
        }
    }

    &__head,
    &__body {
        z-index: 2;
        position: relative;
    }

    &__head {
        display: flex;
        align-items: center;
        gap: 15px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &__img {
        @include wh(60px);
        flex: 0 0 auto;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #fff;

        img {
            @include wh100;
        }
    }

    &__name {
        flex: 1;
        font-size: 1.2rem;
        font-weight: 700;
    }

    &__body {
        margin-top: 15px;
    }

    &__rating {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #efc94c;
        font-size: 12px;
    }
}

.tm-slider {
    .splide {
        &__pagination {
            position: unset;
            margin-top: 20px;

            &__page {
                // background: $primary;
                transition: .3s $easing;

                &.is-active {
                    width: 24px;
                    background: $primary;
                    border-radius: 9999px;
                    transform: scale(1);
                }
            }
        }
    }
}

[data-setbg] {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.section-cta {
    --spadding: 100px;
    color: #fff;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        @include wh100;
        background: $primary;
        opacity: .7;
    }

    // @include media-breakpoint-down(md) {
    //     &::before {
    //         content: '';
    //         @include wh100;
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         background: #fff;
    //         opacity: 0.7;
    //         z-index: 1;
    //     }
    // }

    > .container {
        z-index: 2;
        position: relative;
    }

    h2,p {
        text-transform: uppercase;
    }

    p {
        margin-bottom: 5px;
        font-size: 1.125rem;
        // color: $primary;
        font-weight: 500;
    }

    h2 {
        font-size: 3rem;

        div {
            font-weight: 400;
            font-size: .65em;
        }
    }
}

.bpcard {
    background: #fff;
    box-shadow: 0px 7px 15px 1px rgba(133, 127, 151, 0.1);
    height: 100%;
    border-radius: $border-radius-lg;
    overflow: hidden;

    &__thumb {
        width: 100%;
        height: 275px;

        img {
            @include wh100;
            object-fit: cover;
        }
    }

    &__body {
        padding: 35px 30px 35px;
    }

    &__meta {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;

        &__item {
            display: flex;
            align-items: center;
            gap: 5px;
            color: $primary;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: .85rem;

            div {
                color: #7d7d7d;
            }
        }
    }

    &__title {
        margin-bottom: 15px;
    }

    &__excerpt {
        @include lineClamp(2);
        margin-bottom: 15px;
    }

    &__title,
    &__excerpt {
        color: #414141;
    }

    .btn-arrow {
        color: #2a2a2a;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1;

        > span {
            display: inline-block;
            @include wh(0);
            border-style: solid;
            border-width: 4px 0 4px 4px;
            border-color: transparent transparent transparent #414141;
            margin-inline-start: 30px;
            position: relative;
            transition: .3s $easing;

            &::before {
                content: "";
                position: absolute;
                inset-inline-end: 4px;
                top: 50%;
                transform: translateY(-50%);
                width: 22px;
                height: 2px;
                background: #414141;
                transition: .4s $easing;

                @include rtl {
                    transform: translateY(-50%) translateX(calc(-100% - 7px));
                }
            }

            @include rtl {
                transform: scaleX(-1);
            }
        }

        &:hover {
            color: $primary;

            span {
                margin-inline-start: 40px;

                &::before {
                    width: 30px;
                }
            }
        }
    }
}

.inner-page {
    // header {
    //     flex-direction: row;
    //     --header-h: 60px;
    //     padding: 0 2%;
    //     background: rgba(#111, .7);

    //     &.sticky {
    //         background: #111;
    //     }
    // }

    .section-hero {
        --spadding: 0;
        height: 450px;

        > .content-container {
            position: absolute;
            top: 0;
            left: 0;
            @include wh100;
            @include flexCenter;
            z-index: 2;
            padding: 0 8%;
        }

        .img-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            @include wh100;

            &::after {
                content: '';
                position: absolute;
                @include wh100;
                background: rgba(#111, .5);
                top: 0;
                left: 0;
            }

            img {
                @include wh100;
                object-fit: cover;
            }
        }

        .content {
            @include useFont($font-secondary);
            width: 100%;
            font-weight: 700;
            text-align: center;

            .title {
                font-size: 3rem;
            }
        }

        .breadcrumb {
            font-size: 1.2rem;
            justify-content: center;

            a {
                color: $primary;
                transition: .3s $easing;

                &:hover {
                    color: #fff;
                }
            }

            &-item {
                & + .breadcrumb-item {
                    &::before {
                        font-family: "FontAwesome";
                        padding-inline-end: var(--bs-breadcrumb-item-padding-x);

                        @include rtl {
                            float: right;
                        }
                    }
                }

                &.active {
                    color: #fff;
                    font-weight: 400;
                }
            }
        }

        .follow {
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
}

.feature-box {
    height: 100%;
    background: var(--fbox-bg, #fff);
    box-shadow: $box-shadow-sm;
    padding: 30px;
    border-radius: $border-radius;

    .title {
        font-weight: 700;
        margin: 0 0 10px;
        font-size: 1.25rem;
        text-transform: uppercase;
    }

    .thumb {
        height: 190px;
        @include flexCenter;

        img {
            height: 120px;
            width: auto;
        }
    }
}

.accordion {
    margin-top: 40px;

    &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        // padding-left: 15px;
        background-color: #eeebe5;
        color: #5f5f5f;
        // box-shadow: 0 3px 5px 0 rgba(#000, 0.08);
        // border-radius: 8px;
        font-size: 1rem;
        position: relative;
        z-index: 1;
        font-weight: 600;
        cursor: pointer;
        gap: 15px;

        .heading {
            line-height: 1.5;
        }
    }

    &-item {
        // box-shadow: 0 3px 5px 0 rgba(#000, 0.08);
        border: 0;
        border-radius: 5px;
        // min-height: 55px;
        // border: 1px solid red;
        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: 20px;

            // @media (max-width: 1366px) {
            //     margin-bottom: 8px;
            // }
        }
    }

    &-content {
        // margin-top: -50px;
        // padding-top: 8px;
        background: #fff;
        // box-shadow: 0 2px 5px 1px rgba(#000,.08);
        padding: 0 20px;
        // border-radius: 0 0 15px 15px;
        transition: .25s $easing;
        overflow: hidden;
        // height: 0;
        opacity: 0;
        max-height: 0;
        line-height: 1.9;
        position: relative;
        // transform: scale(0);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: calc(100% - 40px);
            height: 1px;
            background: #ebebeb;
            display: block;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    &-toggle {
        transition: transform .25s $easing;
        font-size: 20px;
        @include wh(50px);
        @include flexCenter;
        background: $primary;
        color: #fff;
        border-radius: 5px;
        flex: 0 0 auto;
    }
}

.accordion-head.shown {
    background: #fff;

    + .accordion-content {
        opacity: 1;
        // height: auto;
        margin-top: 0;
        max-height: 1000px;
        padding: 20px;
        // transform: scale(1);
    }

    .accordion-toggle {
        transform: rotate(-180deg);
        background: linear-gradient(to right, #ff7f01, #ff0501);
    }
}

.contact-form {
    .form-control {
        border: 1px solid #e4e4e4;
        border-radius: 25px;
        padding: 15px 25px;
        color: #909090;
        font-weight: 500;

        &:focus {
            border-color: $primary;
            box-shadow: none;
        }
    }
}

.map {
    width: 100%;
    height: 450px;

    iframe {
        @include wh100;
    }
}

.scard {
    width: 100%;

    &__thumb {
        width: 100%;
        height: 350px;

        img {
            @include wh100;
            object-fit: cover;
            background: #faf9f7;
        }
    }

    .btn {
        border: 0;
        text-transform: uppercase;
        height: 60px;
        font-size: 1.125rem;
    }

    &__enquire {
        background: #eceaea;
        color: #2a2a2a;

        &:hover {
            background: $primary;
            color: #fff;
        }
    }

    &__view {
        background: $primary;
        color: #fff;

        &:hover {
            background: #eceaea;
            color: #2a2a2a;
        }
    }

    &__title {
        font-size: 1.125rem;
    }

    &__rating {
        color: #e3b12e;
        font-size: 10px;
        gap: 5px;
    }
}

.product-single-page {
    .single-wrapper {
        background: #fff;
        padding: 30px;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
        margin-top: -50px;
        position: relative;
        z-index: 1;
    }
}

#prod-main-slider {
    .splide {
        &__arrow {
            background: transparent;
            font-size: 20px;

            &--prev {
                left: var(--arrow-gutter, 10px);
            }

            &--next {
                right: var(--arrow-gutter, 10px);
            }
        }

        &__slide {
            a {
                @include wh100;
                display: block;
                position: relative;

                .zoom {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }

            img {
                @include wh100;
                object-fit: cover;
            }
        }
    }
}

#prod-thumbnail-slider {
    .splide {
        &__slide {
            opacity: .6;
            transition: opacity .3s $easing;
            border: 0;

            img {
                @include wh100;
                object-fit: cover;
            }

            &.is-active {
                opacity: 1;
            }
        }
    }
}

.sprod {
    &__details {
        padding: 20px;
    }

    &__desc {
        line-height: 1.5;
        font-weight: 500;
        margin-top: 20px;
    }

    &__extra {
        margin-top: 50px;

        .tab-pane {
            h5 {
                margin-bottom: 15px;
            }

            .table-responsive {
                width: 500px;
                max-width: 100%;
            }
        }
    }
}

.text {
    &-start {
        text-align: start !important;
    }
}

.modal-header {
    .btn-close {
        @include rtl {
            margin: auto calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5);
        }
    }
}

.pagination-wrapper {
    margin-top: 20px;
}

.mb {
    &-30 {
        margin-bottom: 30px;
    }
}

.catcard {
    width: 100%;
    height: 280px;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        background: rgba(#000, .5);
        border: 15px solid rgba(#000, .3);
        z-index: 2;
        @include wh100;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__img {
        @include wh100;
        object-fit: cover;
        position: relative;
        z-index: 1;
        transition: 1s $easing;
    }

    &__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 700;
        font-size: 3rem;
        color: #fff;
        z-index: 3;
        width: 100%;
        text-align: center;
    }

    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

.section-vision {
    .row {
        > div {
            --fbox-bg: #{$primary};
            color: #fff;

            &:nth-child(even) {
                --fbox-bg: #{$light1};
                color: #111;
            }
        }
    }
}

#gtranslate {
    .form-select {
        background-color: $primary;
        border-color: $primary;
        font-weight: 700;
    }
}

.header-social {
    display: flex;
    align-items: center;
    color: #fff;
    gap: 15px;

    a {
        color: currentColor;
    }
}

.iti.iti--allow-dropdown {
    width: 100%;
}

.iti__flag {
    background-image: url("../images/flags.webp") !important;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../images/flags@2x.webp") !important;
    }
}

.iti__selected-flag {
    border-radius: 9999px 0 0 9999px;
}

.section-blog-content {
    h2.title {
        margin-bottom: 2rem;
    }

    ol,ul {
        li {
            line-height: 2;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}
